import client from "apollo/client";
import Cookie from "js-cookie";

const Session = {
  signout(cb) {
    this.clearCookies();
    client.resetStore();
    cb && cb();
  },
  setCookies({ email, token }) {
    return new Promise((resolve) => {
      Cookie.set("UserEmail", email, { secure: true, expires: 7 });
      Cookie.set("UserAuth", token, { secure: true, expires: 7 });
      resolve();
    });
  },
  clearCookies() {
    Cookie.remove("UserEmail");
    Cookie.remove("UserAuth");
  },
};

export default Session;
