import Session from "apollo/actions/session";
import axios from "@axiosConfig";
import Cookie from "js-cookie";

const POLLING_INTERVAL = 30 * 60000; // 30 minutes

const CheckSessionTimeoutPoller = () => {
    const checkLoggedIn = () => {
        const userEmail = Cookie.get("UserEmail");

        // Don't check if session is expired if not logged in:
        if (!userEmail) {
            return;
        }

        axios.get("/users/me/check_logged_in").then(() => {
            // do nothing.
        }).catch((error) => {
            // If get a 401 Unauthorized response:
            if (error.response.status === 401) {
                axios.patch("/users/me/log_out").then(() => {
                    Session.signout(() => (window.location = "/"));
                }).catch((error) => {
                    console.log(error);
                });
            }
        });
    };

    setInterval(() => checkLoggedIn(), POLLING_INTERVAL);

    return null;
};

export default CheckSessionTimeoutPoller;
