import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  defaults: {
    reportFilters: {
      __typename: "ReportFilters",
      accountSets: ["active", "paused"],
      startDate:
        localStorage.getItem("reporting-start-date") ||
        moment().subtract(7, "days").startOf("day").format(DATE_FORMAT),
      endDate:
        localStorage.getItem("reporting-end-date") ||
        moment().endOf("day").format(DATE_FORMAT),
      platform: (
        localStorage.getItem("reporting-platform") ||
        "facebook,instagram,youtube,facebook_ad,tiktok,tiktok_ad"
      ).split(","),
      paidOrganic: localStorage.getItem("paid-organic") || "All",
    },
  },
  resolvers: {
    Mutation: {
      updateReportFilters: (obj, args, { cache }) => {
        if (args.input.startDate) {
          if (args.input.startDate._isAMomentObject)
            args.input.startDate = args.input.startDate.format(DATE_FORMAT);
          localStorage.setItem("reporting-start-date", args.input.startDate);
        }
        if (args.input.endDate) {
          if (args.input.endDate._isAMomentObject)
            args.input.endDate = args.input.endDate.format(DATE_FORMAT);
          localStorage.setItem("reporting-end-date", args.input.endDate);
        }
        if (args.input.platform) {
          localStorage.setItem("reporting-platform", args.input.platform);
        }
        if (args.input.paidOrganic) {
          localStorage.setItem("paid-organic", args.input.paidOrganic);
        }
        cache.writeData({
          data: {
            reportFilters: { __typename: "ReportFilters", ...args.input },
          },
        });
        return null;
      },
    },
  },
};
