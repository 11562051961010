import React from "react";
import { Route, Redirect } from "react-router-dom";
import { CurrentUser } from "apollo/actions/users";

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <CurrentUser>
          {({ data, loading, error }) => {
            if (error) return <Redirect to="/session/login" />;
            if (loading || !data) return null;
            return data.currentUser ? (
              Component ? (
                <Component {...props} currentUser={data.currentUser} />
              ) : (
                render({ currentUser: data.currentUser, ...props })
              )
            ) : (
              <Redirect to="/session/login" />
            );
          }}
        </CurrentUser>
      )}
    />
  );
};

export default PrivateRoute;
