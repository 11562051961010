import Cookie from "js-cookie";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { RestLink } from "apollo-link-rest";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import state from "./resolvers";
import * as Sentry from "@sentry/browser";
// import formatReportsLink from "apollo/links/formatReportsLink";
// import { toIdValue } from "apollo-utilities";
const cache = new InMemoryCache({
  cacheRedirects: {
    // Query: {
    //   currentUser: (_, args) => {
    //     return toIdValue(
    //       cache.config.dataIdFromObject({
    //         __typename: "CurrentUser",
    //         id: args.id
    //       })
    //     );
    //   }
    // }
  },
});

const setAuthHeadersLink = setContext((request, previousContext) => {
  let headers = {};
  if (request.operationName === "Login") {
    const { email, password } = request.variables;
    if (email && password) {
      let str = email + ":" + password,
        auth_string = btoa(unescape(encodeURIComponent(str)));
      headers = {
        Authorization: "Basic " + auth_string,
      };
    }
  } else {
    headers = {
      "X-User-Email": Cookie.get("UserEmail"),
      "X-User-Token": Cookie.get("UserAuth"),
    };
  }

  if (
    request.operationName === "BusinessesToken" ||
    request.operationName === "createBusinessAccount" ||
    request.operationName === "exchangeTokens" ||
    request.operationName === "Validate"
  ) {
    if (request.variables && request.variables.token) {
      headers = {
        "X-Agency-Token": request.variables.token,
      };
    }
  }

  return {
    headers,
  };
});

const restLink = new RestLink({
  uri: process.env.REACT_APP_API_URL,
  endpoints: {
    mock: "https://private-c3fb2-socialcentiv1.apiary-mock.com",
  },
  credentials: "omit",
  headers: {
    "Content-type": "application/json",
    Accept: "application/vnd.socialcentiv.v2",
  },
});

const orgLink = setContext((_, { headers }) => {
  const orgId = sessionStorage.getItem('selectedOrgId');

  return {
    headers: {
      ...headers,
      ...(orgId ? { 'X-Org-ID': orgId } : {}),
    },
  };
});

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log("[GraphQL error]: Message: ", graphQLErrors);
      graphQLErrors.forEach((error) => Sentry.captureException(error));
    }
    if (networkError) {
      console.log(`[Network error]: `, networkError);
      console.log(operation);
      Sentry.captureException(networkError);
    }
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, setAuthHeadersLink, orgLink, restLink]),
  cache,
  resolvers: state.resolvers,
  setConnectToDevTools: true,
});

cache.writeData({ data: state.defaults });

client.onResetStore(() => cache.writeData({ data: state.defaults }));

export { client };
export default client;
